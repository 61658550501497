/* 
	green: rgb(0, 102, 0)
	brown: rgb(115, 77, 38)
	gold: rgb(204, 153, 0)
	mud: rgb(153, 102, 0)
	white: rgb(255, 253, 248)
*/

export default {
	colors: {
		aqua: '#00B4C6',
		grey: '#9BB0B9',
		ocean: '#366274',
		teal: '#49A3B7',
		mud: '#996600',
		white: '#fffdf8',
		green: "#006600",
		brown: "#734d26",
		gold: "#cc9900"
	},
	containerSizes: {
		large: 1700,
		mLarge: 1400,
		medium: 1200,
		blog: 1080,
		small: 840,
		navMobile: 680,
		mobile: 540
	},
	breakpoints: {
		large: `max-width: 1700px`,
		mLarge: `max-width: 1400px`,
		medium: `max-width: 1200px`,
		blog: `max-width: 1080px`,
		small: `max-width: 840px`,
		navMobile: `max-width: 680px`,
		mobile: `max-width: 540px`,
		desktop: `min-width: 541px`
	},
	hPadding: {
		medium: "50px",
		small: "20px"
	},
	aspectRatio: {
		sixteenNine: "56.25%",
		twoThree: "75%",
		square: "100%"
	},
	fonts: {
		basic:'Montserrat',
		bold: 'Montserrat Bold'
	}
}
