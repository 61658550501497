import React from "react"
import styled from "@emotion/styled"
import { bool, func } from "prop-types"
import theme from "../../styles/theme"

const Hamburger = ({ menuOpen, setMenuOpen, currentPage }) => {
	return (
		<>
			<HamburgerButton
				menuOpen={menuOpen}
				onClick={() => setMenuOpen(!menuOpen)}
				aria-label="Menu"
				currentPage={currentPage}
			>
				<BurgerLine />
				<BurgerLine />
				<BurgerLine />
			</HamburgerButton>
		</>
	)
}

Hamburger.propTypes = {
	menuOpen: bool.isRequired,
	setMenuOpen: func.isRequired
}

export default Hamburger

const HamburgerButton = styled.button`
	display: none;
	@media (${theme.breakpoints.navMobile}) {
		position: relative;
		font-size: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		width: 2rem;
		height: 1.5rem;
		background: transparent;
		border: none;
		padding: 0;
		font-weight: 600;
		align-items: center;
		cursor: pointer;
		pointer-events: all;
		z-index: 10;

		&:focus {
			outline: none;
		}

		div {
			width: 1.8rem;
			height: 2px;
			transition: all 0.3s linear;
			position: relative;
			transform-origin: 3.5px;
			background: ${({ menuOpen }) =>
				menuOpen ? theme.colors.emerald : theme.colors.white};

			&:first-of-type {
				transform: ${({ menuOpen }) =>
					menuOpen ? "rotate(45deg)" : "rotate(0)"};
			}

			&:nth-of-type(2) {
				opacity: ${({ menuOpen }) => (menuOpen ? "0" : "1")};
			}
			&:last-of-type {
				transform: ${({ menuOpen }) =>
					menuOpen ? "rotate(-45deg)" : "rotate(0)"};
			}
			background: ${theme.colors.green};
		}
	}
`
const BurgerLine = styled.div``
