import { Link } from 'gatsby'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import theme from '../../styles/theme'
import { MaxWidthContainer, headerFooter, fluid } from '../../styles/mixins'
import logo from '../../images/main-logo.png'
import mainPhoto from '../../images/peggy.jpg'
import Hamburger from "./hamburger"

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <MainHeader>
      {menuOpen &&
        <MobileNav>
            <Link
              to="/about"
              onClick={() => setMenuOpen(false)}
            >about</Link>
            <Link
              to="/books"
              onClick={() => setMenuOpen(false)}
            >books</Link>
            <Link
              to="/blog"
              onClick={() => setMenuOpen(false)}
            >blog</Link>
            <Link
              to="/contact"
              onClick={() => setMenuOpen(false)}
            >contact</Link>
        </MobileNav>
      }
      <MaxWidthContainer className="max-width-container">
        <Hamburger
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
        />
        <Link to="/" className="desktop main-logo"><MainLogo src={logo} alt="logo"/></Link>
				<div className="center-header-container">
					<h1>PEGGY SIAS LANTZ</h1>
					<h2>Author, Editor, and Florida Master Naturalist</h2>
        <Nav>
          <div className="link-holder">
            <Link
              activeClassName="activated"
              to="/about"
              partiallyActive={true}>about</Link>
          </div>
          <div className="link-holder">
            <Link 
              activeClassName="activated"
              to="/books"
              partiallyActive={true}
            >books</Link>
          </div>
          <div className="link-holder">
            <Link 
              activeClassName="activated"
              to="/blog"
              partiallyActive={true}
            >blog</Link>
          </div>
          <div className="link-holder">
            <Link
              activeClassName="activated"
              to="/contact"
              partiallyActive={true}
            >contact</Link>
          </div>
        </Nav>
				</div>
        <a className="mail-icon" href="mailto:dev@catch-nyc.com" target="_blank">
          <ProfilePic src={mainPhoto} alt="email" />
        </a>
				<Link to="/" className="mobile main-logo"><MainLogo src={logo} alt="logo"/></Link>
      </MaxWidthContainer>
    </MainHeader>
  )
}

export default Header

const MainHeader = styled.header`
  ${headerFooter}
	.center-header-container {
		color:${theme.colors.green};
		font-family:Times, serif;
		text-align:center;
		h1, h2 {
			font-weight:bold;
			font-family:Times, serif;
		}
		h1 {
			${fluid('font-size', 18, 42,600, 1200)}
		}
		h2 {
			${fluid('font-size', 16, 22,600, 1200)}
			font-style: italic;
			@media(${theme.breakpoints.navMobile}) {
				padding: 0 20px;
			}
		}
	}
	.desktop {
		@media(${theme.breakpoints.navMobile}) {
			display:none;
			
		}
	}
	.mobile.main-logo {
		img {
			max-width:50px;
		}
	}
	
	@media(${theme.breakpoints.desktop}) {
		.mobile {
			display:none;
		}
	}
`

const MainLogo = styled.img`
  max-width: 90px;
`

const Nav = styled.nav`
  display: flex;
  flex-grow: 1;
  .link-holder {
		margin-top:30px;
		text-transform:uppercase;
    a {
      color: ${theme.colors.green};
      margin: 0 10px;
			padding:5px 0;
			border: solid 3px ${theme.colors.mud};
			font-family:${theme.fonts.bold};
			width: 114px;
			display:block;
      transition: all .3s ease-out;
      &:hover {
        color:${theme.colors.white};
				background-color:${theme.colors.green};
      }
			&.activated {
        background-color:${theme.colors.gold};
				color:${theme.colors.white};
				&:hover {
					background-color:${theme.colors.gold};
				}
      }
    }
  }
  
  
  @media(${theme.breakpoints.navMobile}) {
    display:none;
  }
`
const MobileNav = styled.nav`
  display:none;
  z-index:1;
  position: absolute;
  background: ${theme.colors.white};
  flex-direction: column;
  width: 100%;
  left: 0;
  top:90px;
  height:100%;
  font-size:30px;
  
  a {
    color:${theme.colors.green} !important;
    font-family: ${theme.fonts.bold};
    margin: 40px 20px;
    &:visited {
      color:${theme.colors.green} !important;
    }
  }

  @media(${theme.breakpoints.navMobile}) {
    display: flex;
  }
  
`
const ProfilePic = styled.img`
  width:130px;
	@media(${theme.breakpoints.navMobile}) {
    display:none;
  }
`
