import React from 'react'
import Header from './modules/header'
import Footer from './modules/footer'
import "../styles/layout.css"

const Layout = ({ children, siteTitle }) => (
  <>
    <Header 
      siteTitle={siteTitle}
    />
    <div>{children}</div>
    <Footer />
  </>
)

export default Layout
