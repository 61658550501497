import React from 'react'
import styled from '@emotion/styled'
import theme from '../../styles/theme'
import { MaxWidthContainer, headerFooter } from '../../styles/mixins'
import { Link } from 'gatsby'

const Footer = () => {
    return (
        <MainFooter>
            <MaxWidthContainer className="max-width-container">
                    <div className="left">
                    © {new Date().getFullYear()} Peggy Sias Lantz - All Rights Reserved
                    </div>
                    {/* <div className="right">
                        <Link to="/terms">terms &amp; conditions</Link>
                        <Link to="/privacy">privacy policy</Link>
                    </div> */}
            </MaxWidthContainer>
        </MainFooter>
    )
}

export default Footer

const MainFooter = styled.footer`
    ${headerFooter}
    display:flex;
    min-height: 35px;
		background-color:${theme.colors.green};
    .max-width-container {
        font-size:12px;
        color:${theme.colors.white};
        a {
            color:${theme.colors.white};
            &:last-child {
                margin-left:20px;
            }
        }
    }
    @media(${theme.breakpoints.navMobile}) {
        .max-width-container {
            flex-direction:column;
            .left {
                margin-bottom:10px;
            }
        }
    }
`