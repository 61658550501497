import theme from "./theme"
import styled from "@emotion/styled"
import { css } from '@emotion/react'

export const fluid = (
	property,
	minSize,
	maxSize,
	minScreenWidth,
	maxScreenWidth
) => {
	return `
		${property}: calc( ${minSize}px + (${maxSize - minSize}) 
		* ((100vw - ${minScreenWidth}px) / (${maxScreenWidth - minScreenWidth})) );

        @media screen and (min-width:${maxScreenWidth}px) {
            ${property}: ${maxSize}px;
        }
        @media screen and (max-width:${minScreenWidth}px) {
            ${property}: ${minSize}px;
        }
    `
}
export const standardHPadding = css`
	padding-left:${theme.hPadding.medium};
	padding-right:${theme.hPadding.medium};
	@media(${theme.breakpoints.small}) {
		padding-left:${theme.hPadding.small};
		padding-right:${theme.hPadding.small};
	}
`
export const MaxWidthContainer = styled.div`
	${standardHPadding}
	max-width: ${props =>
		props.maxW ? props.maxW : `${theme.containerSizes.mLarge}px`};
	margin: auto;
	position: relative;
	width:calc(100% - 100px);
	@media(${theme.breakpoints.mobile}) {
		padding-left:20px;
		padding-right:20px;
		width:calc(100% - 40px);
	}
`
export const MidWidthContainer = styled(MaxWidthContainer)`
	max-width:${theme.containerSizes.medium}px;
`
export const headerStyle = css`
	${fluid('font-size', 30, 42, 600, 1400)}
	font-family:"Montserrat Bold";
	text-transform:uppercase;
		margin-bottom:20px;
	text-align:left;
	color:${theme.colors.white};

`
export const pStyle = css`
	line-height:1.3;
	${fluid('font-size', 20, 24, 600, 1400)}
	text-align:left;
	padding:8px 0;
	max-width:700px;
	font-family:times, serif;
	font-weight:bold;
	strong {
		font-family:${theme.fonts.bold};
	}
`
export const listStyle = css`
	margin-top:20px;
	li {
		margin-left: 40px;
		margin-bottom: 18px;
		line-height:1.3;
	}
`
export const headerFooter = css`
	width: 100%;
	background-color: white;
	padding: 20px 0;
	.max-width-container {
		display: flex;
		justify-content: space-between;
		height: 100%;
		align-items: center;
	}
`
export const cta = css`
	a {
		border-radius:40px;
		display:inline-block;
		color:${theme.colors.white};
		padding:10px 20px;
		font-family:${theme.fonts.bold};
		text-transform:uppercase;
		${fluid('font-size', 14, 16, 600, 1200)}
		transition: all .3s ease-out;
		background-color:${theme.colors.gold};
		&:hover {
			background-color:${theme.colors.mud};
		}
	}

`